import { useEffect, useState } from 'react';
import './App.css';
import TempModal from './components/modals/TempModal';
function App() {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // Show the modal after 1 second
    const timer = setTimeout(() => {
      setOpen(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      <div className="udux">
      </div>
       <TempModal open={open} handleClose={() => setOpen(false)} />
    </div>
  );
}

export default App;
